import { HeadFC, Link } from "gatsby";
import * as React from "react"
import Button from "../components/Button";
import Layout from "../components/Layout";
import { CheckCircleIcon } from '@heroicons/react/24/outline'

const ThankYouPage = () => {
  return (
    <Layout pageTitle="Dziękujemy">
      <div className="m-auto text-center space-y-4">
        <CheckCircleIcon className="mx-auto h-32 w-32 text-teal-600"/>
        <p className="text-3xl font-semibold text-gray-900">Formularz wysłany.</p>
        <p className="text-lg leading-relaxed text-gray-700">Oddzwonimy i umówimy się na wizytę</p>
        <Button as={Link} to="/">Strona główna</Button>
      </div>
    </Layout>
  );
}

export default ThankYouPage;